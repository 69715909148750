import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  // Create a BehaviorSubject with an initial value
  private dataSubject = new BehaviorSubject<any>('Initial Data');

  // Observable to subscribe to changes in data
  data$ = this.dataSubject.asObservable();

  // Method to update the data
  updateData(newData: any) {
    this.dataSubject.next(newData);
  }

  constructor() {}
}

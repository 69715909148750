import {
  Component,
  OnInit,
  HostListener,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OnDemandPreloadService } from 'src/app/services/pre-loading-strategies/on-demand-preload.service';
import { LocationService } from 'src/app/services/location/location.service';
import { SubSink } from 'subsink';
import { LocationPopupComponent } from './location-popup/location-popup.component';
import { OpenSidebarService } from 'src/app/services/open-sidebar/open-sidebar.service';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewHeaderComponent implements OnInit {
  city;
  unsubscribe$ = new SubSink();

  constructor(
    private preloadService: OnDemandPreloadService,
    private dialog: MatDialog,
    private locationSerivce: LocationService,
    private sidebarService: OpenSidebarService
  ) {}

  ngOnInit(): void {
    this.getLocation();
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 100) {
      let element = document.getElementById('header');
      element.classList.add('hf-sticky');
    } else {
      let element = document.getElementById('header');
      element.classList.remove('hf-sticky');
    }
  }

  getLocation() {
    this.unsubscribe$.add(
      this.locationSerivce.location$.subscribe((res) => {
        if (res) {
          this.city = res.city;
        }
      })
    );
  }

  openPopup() {
    this.dialog.open(LocationPopupComponent, {
      panelClass: 'my-custom-dialog-class',
    });
  }
  preloadModule(routeName) {
    this.preloadService.startPreload(routeName);
  }

  openSidebar() {
    this.sidebarService.openSidebar();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.unsubscribe();
  }
}

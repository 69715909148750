import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface LocationData {
  lat: number;
  lng: number;
  city: string;
  country: string;

  range: number;
}

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  locationData: { lng: number; lat: number; locationAccess: boolean };
  defaultLocation = {
    lat: 8.9824,
    lng: 79.5199,
    range: 25,
    city: 'Panama City',
    country: 'Panama',
  };
  private locationSource = new BehaviorSubject(this.setIntialLocation());
  public location$ = this.locationSource.asObservable();

  constructor() {}

  // get current location's lat amd lng
  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (resp) => {
          this.locationData = {
            lng: resp.coords.longitude,
            lat: resp.coords.latitude,
            locationAccess: true,
          };
          resolve(this.locationData);
        },
        (err) => {
          this.locationData = {
            lng: 0,
            lat: 0,
            locationAccess: false,
          };
          resolve(this.locationData);
        }
      );
    });
  }

  // to set  default location whenever the user visits the website for the very first time
  setIntialLocation() {
    if (localStorage.getItem('location') !== null) {
      return JSON.parse(localStorage.getItem('location'));
    } else {
      localStorage.setItem('location', JSON.stringify(this.defaultLocation));
      return this.defaultLocation;
    }
  }

  // to set new location
  setLocation(location) {
    const loc = JSON.parse(localStorage.getItem('location'));
    if (
      Object.entries(loc).toString() === Object.entries(location).toString()
    ) {
      console.log('equal location');
    } else {
      this.locationSource.next(location);
      localStorage.setItem('location', JSON.stringify(location));
    }
  }
}
